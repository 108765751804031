import { useTranslations } from "next-intl";
import React, { useState } from "react";
import { isDachProduct } from "../../helpers/isDachProduct";
import { getRoute } from "../../helpers/urls/getRoute";
import { AdSlot } from "../Ad/slots";
import { useCountry } from "../ApplicationState/hooks";
import { Button } from "../Button";
import { sendError } from "../ErrorBoundary/helpers";
import { LoadingWrapper } from "../LoadingWrapper";
import styles from "./content.module.scss";

type NewDataUpdatePopupProps = {
    reload: () => void;
};

export const NewDataUpdateContent: React.FC<NewDataUpdatePopupProps> = ({
    reload,
}: NewDataUpdatePopupProps) => {
    const t = useTranslations("component.newDataUpdateLayer");
    const country = useCountry();
    const [isLoading, setIsLoading] = useState(false);
    const config = {
        de: {
            title: "Zum aktuellen Deutschlandwetter",
            description: "zum aktuellen Deutschlandwetter",
        },
        at: {
            title: "Zum aktuellen Österreichwetter",
            description: "zum aktuellen Österreichwetter",
        },
        ch: {
            title: "Zum aktuellen Schweizwetter",
            description: "zum aktuellen Schweizwetter",
        },
        pl: {
            title: "",
            description: "",
        },
    };

    try {
        return (
            <div className={styles.wrapper}>
                <div className={styles.dialog}>
                    <div className={styles.inner}>
                        <span className={styles.warningIcon}>
                            <span className="icon-notice" />
                        </span>
                        <p className={styles.message}>{t("text")}</p>
                        <Button
                            className={styles.reload}
                            variant={isLoading ? "secondary" : "primary"}
                            fullWidth={true}
                            onClick={() => {
                                setIsLoading(true);
                                reload();
                            }}
                            data-action="confirm"
                        >
                            {isLoading ? (
                                <LoadingWrapper minHeight={20} />
                            ) : (
                                <span>{t("buttonLabel")}</span>
                            )}
                        </Button>
                    </div>
                    {isDachProduct(country) && (
                        <a
                            className={styles.link}
                            href={getRoute({
                                parameters: {
                                    pageName: "video",
                                },
                                country,
                            })}
                            aria-label={config[country].title}
                            title={config[country].title}
                        >
                            {config[country].description}
                            <span className="icon-arrow-link" />
                        </a>
                    )}
                </div>
                <div className={styles.adWrapper}>
                    <AdSlot
                        slot={{
                            de: "wetter_vorhersage_performancebanner3",
                            at: "WET_AT_M_performancebanner3",
                            ch: "WET_CH_M_performancebanner3",
                            pl: undefined,
                        }}
                        minHeight="250px"
                    />
                </div>
            </div>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
